import React from 'react'

const IconSection = () => {
  return (
     /* ===================================== Icon Section ======================================= */
     <div>
     <div className="cards card__section__icons">
         <div className="card">
             <div className="card__section">
                 <img 
                     className="card__icon" 
                     src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1705882467/6144_ctin48.jpg" 
                     alt="empathetic" 
                 />
             </div>
             <div className="card__section">
                 <h2>Empathetic Listening</h2>
             </div>
             <div className="card__section">
                 <p>Every word matters, every<br/>emotion heard.</p>
             </div>
         </div>

         <div className="card">
             <div className="card__section">
                 <img 
                     className="card__icon" 
                     src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1705882457/5904_wxmoec.jpg" 
                     alt="Individual" 
                 />
             </div>
             <div className="card__section">
                 <h2>Individual Healing Plan</h2>
             </div>
             <div className="card__section">
                 <p>Your path to healing, carefully <br/>mapped.</p>
             </div>
         </div>

         <div className="card">
             <div className="card__section">
                 <img
                     className="card__icon" 
                     src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1705882441/SL-091823-63290-36_chawan.jpg" 
                     alt="Group" 
                 />
             </div>
             <div className="card__section">
                 <h2>Group Therapy Support</h2>
             </div>
             <div className="card__section">
                 <p>United for healing, stronger<br/>together.</p>
             </div>
         </div>

         <div className="card">
             <div className="card__section">
                 <img
                     className="card__icon" 
                     src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1705882477/20499_l80zlh.jpg" 
                     alt="Personalize" 
                 />
             </div>
             <div className="card__section">
                 <h2>Personalized Therapy</h2>
             </div>
             <div className="card__section">
                 <p>Your journey, your healing, one<br/> step at time.</p>
             </div>
         </div>
     </div>         
 </div>
/* ===================================== Icon Section ======================================= */
  )
}

export default IconSection