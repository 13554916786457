import React from 'react'
import { HashLink as Link } from 'react-router-hash-link';

const SectionProgramCard = () => {
  return (
 
    /* ============================= We want to talk with you ================================= */

<div>
    <div className="program__social">
        <div className="program__social__icon">
            <a href="">
                <img 
                    src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1706080390/instagram-genesis_n62mkf.png" 
                    alt="instagram" 
                    className="media"
                />
            </a>
            <a href="">
                <img 
                    src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1706080390/facebook-genesis_ejsuuu.png" 
                    alt="facebook" 
                    className="media"
                />
            </a>
            <a href="">
                <img 
                    src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1706080390/whatsapp-genesis_qrftkh.png" 
                    alt="whatsap" 
                    className="media"
                />
            </a>
        </div>
        {/* Card */}
        <div className="program__social__explore">
            {/* Depression */}
            <div className="program__social__explore__card">
                <img 
                    className='program__social__explore__card__image__top'
                    src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1705952555/pexels-cottonbro-studio-4100643_lurw2g.jpg" 
                    alt="Depression"
                />
                <div className="program__social__explore__info">
                    <div className="program__social__explore__title">
                        <h4>Depression</h4>
                    </div>
                    <div className="home__content__button home__content__button__blue__cover">
                        <a href="">
                            <Link to='/programs#depression__program'>
                                <button className='button__explore'>Explore</button>
                            </Link>
                        </a>
                    </div>
                </div>
            </div>

            {/* Anxiete */}
            <div className="program__social__explore__card">
                <img 
                    className='program__social__explore__card__image__top'
                    src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1705952555/pexels-julia-larson-6456293_quun0q.jpg" 
                    alt="Anxiete"
                />
                <div className="program__social__explore__info">
                    <div className="program__social__explore__title">
                        <h4>Anxiete</h4>
                    </div>
                    <div className="home__content__button home__content__button__blue__cover">
                        <Link to='/programs#anxiete__program'>
                            <button className='button__explore'>Explore</button>
                        </Link>
                    </div>
                </div>
            </div>

            {/* Negative */}
            <div className="program__social__explore__card">
                <img 
                    className='program__social__explore__card__image__top'
                    src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1705952554/medium-shot-people-helping-sad-woman_zleaw6.jpg" 
                    alt="Negative"
                />
                <div className="program__social__explore__info">
                    <div className="program__social__explore__title">
                        <h4>Negative</h4>
                    </div>
                    <div className="home__content__button home__content__button__blue__cover">
                        <Link to='/programs#negative__program'>
                            <button className='button__explore'>Explore</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
        <div className="program__social__explore">
            {/* Mental Health */}
            <div className="program__social__explore__card">
                <img 
                    className='program__social__explore__card__image__top'
                    src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1705952554/pexels-ketut-subiyanto-4719936_jcva8t.jpg" 
                    alt="Mental Health"
                />
                <div className="program__social__explore__info">
                    <div className="program__social__explore__title">
                        <h4>Mental Health</h4>
                    </div>
                    <div className="home__content__button home__content__button__blue__cover">
                        <Link to='/programs#mentalhealth__program'>
                            <button className='button__explore'>Explore</button>
                        </Link>
                    </div>
                </div>
            </div>

            {/* Fear */}
            <div className="program__social__explore__card">
                <img 
                    className='program__social__explore__card__image__bottom'
                    src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1706999107/wepik-export-202402032224495u9r_iwgfcd.png" 
                    alt="Fear"
                />
                <div className="program__social__explore__info">
                    <div className="program__social__explore__title">
                        <h4>Fear</h4>
                    </div>
                    <div className="home__content__button home__content__button__blue__cover">
                        <Link to='/programs#fear__program'>
                            <button className='button__explore'>Explore</button>
                        </Link>
                    </div>
                </div>
            </div>

            {/* Frustation */}
            <div className="program__social__explore__card">
                <img 
                    className='program__social__explore__card__image__bottom'
                    src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1705952553/pexels-rdne-stock-project-7468241_xxng5i.jpg" 
                    alt="Frustation"
                />
                <div className="program__social__explore__info">
                    <div className="program__social__explore__title">
                        <h4>Frustation</h4>
                    </div>
                    <div className="home__content__button home__content__button__blue__cover">
                        <Link to='/programs#frustration__program'>
                            <button className='button__explore'>Explore</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>

        {/* Card Mini Tablet */}
        <div id='mini__tablet__programs__card__line__1' className="program__social__explore program__social__explore__mini__tablet">
            {/* Depression */}
            <div className="program__social__explore__card">
                <img 
                    className='program__social__explore__card__image__top'
                    src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1705952555/pexels-cottonbro-studio-4100643_lurw2g.jpg" 
                    alt="Depression"
                />
                <div className="program__social__explore__info">
                    <div className="program__social__explore__title">
                        <h4>Depression</h4>
                    </div>
                    <div className="home__content__button home__content__button__blue__cover">
                        <a href="">
                            <Link to='/programs#depression__program'>
                                <button className='button__explore'>Explore</button>
                            </Link>
                        </a>
                    </div>
                </div>
            </div>

            {/* Anxiete */}
            <div className="program__social__explore__card">
                <img 
                    className='program__social__explore__card__image__top'
                    src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1705952555/pexels-julia-larson-6456293_quun0q.jpg" 
                    alt="Anxiete"
                />
                <div className="program__social__explore__info">
                    <div className="program__social__explore__title">
                        <h4>Anxiete</h4>
                    </div>
                    <div className="home__content__button home__content__button__blue__cover">
                        <Link to='/programs#anxiete__program'>
                            <button className='button__explore'>Explore</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>

        <div id='mini__tablet__programs__card__line__2'  className="program__social__explore program__social__explore__mini__tablet">
            {/* Negative */}
            <div className="program__social__explore__card">
                <img 
                    className='program__social__explore__card__image__top'
                    src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1705952554/medium-shot-people-helping-sad-woman_zleaw6.jpg" 
                    alt="Negative"
                />
                <div className="program__social__explore__info">
                    <div className="program__social__explore__title">
                        <h4>Negative</h4>
                    </div>
                    <div className="home__content__button home__content__button__blue__cover">
                        <Link to='/programs#negative__program'>
                            <button className='button__explore'>Explore</button>
                        </Link>
                    </div>
                </div>
            </div>
        
            {/* Mental Health */}
            <div className="program__social__explore__card">
                <img 
                    className='program__social__explore__card__image__top'
                    src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1705952554/pexels-ketut-subiyanto-4719936_jcva8t.jpg" 
                    alt="Mental Health"
                />
                <div className="program__social__explore__info">
                    <div className="program__social__explore__title">
                        <h4>Mental Health</h4>
                    </div>
                    <div className="home__content__button home__content__button__blue__cover">
                        <Link to='/programs#mentalhealth__program'>
                            <button className='button__explore'>Explore</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>

        <div id='mini__tablet__programs__card__line_3' className="program__social__explore program__social__explore__mini__tablet">
            {/* Fear */}
            <div id='mini__tablet__programs__card__line__4' className="program__social__explore__card">
                <img 
                    className='program__social__explore__card__image__bottom'
                    src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1706999107/wepik-export-202402032224495u9r_iwgfcd.png" 
                    alt="Fear"
                />
                <div className="program__social__explore__info">
                    <div className="program__social__explore__title">
                        <h4>Fear</h4>
                    </div>
                    <div className="home__content__button home__content__button__blue__cover">
                        <Link to='/programs#fear__program'>
                            <button className='button__explore'>Explore</button>
                        </Link>
                    </div>
                </div>
            </div>

            {/* Frustation */}
            <div id='mini__tablet__programs__card__line__5' className="program__social__explore__card">
                <img 
                    className='program__social__explore__card__image__bottom'
                    src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1705952553/pexels-rdne-stock-project-7468241_xxng5i.jpg" 
                    alt="Frustation"
                />
                <div className="program__social__explore__info">
                    <div className="program__social__explore__title">
                        <h4>Frustation</h4>
                    </div>
                    <div className="home__content__button home__content__button__blue__cover">
                        <Link to='/programs#frustration__program'>
                            <button className='button__explore'>Explore</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


/* =============================== We want to talk with you ================================= */

  )
}

export default SectionProgramCard