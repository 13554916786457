import React from 'react'
import FreeConsultationForm from '../../../../form/FreeConsultationForm'

const TowardsCover = () => {
  return (
     /* ============================= Toward ================================= */

<div className="testimonial about toward__section__laptop">
    <div className="main">
        <img 
            className='logo'
            src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1705872503/WhatsApp_Image_2024-01-15_at_09.14.46_klkslx.jpg" 
            alt="logo"
        /> 
        <div className="main__team home__sub__testimony about__toward__container">
            <div className="main__team__description home__sub__testimony__description about__toward__description">
                <h2 id='toward__healing__and__reconciliation'>Towards Healing<br/>and Reconciliation<br/></h2>
                <h3>
                    In our clinic, "To wards Enlightened
                    Freedom is not just a phrase; it's a
                    comforting pledge through out your
                    journey to healing.
                    <br /><br/>
                    We understand that this path may seem
                    intricate, but with our dedicated team,
                    each step is marked by compassion and a
                    commitment to your well-being.
                    <br/><br/>
                    To our client, families,and friends, rest
                    assured you are not alone. Together, we
                    walk hand towords a light guided
                    by inner healing and rediscovered freedom.
                </h3>
            </div>
            <div className="main__team__image home__sub__testimony__image about__toward__image">
                <img
                    src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1707080328/wepik-export-20240204205833AbzT_rxqfzl.jpg" 
                    alt="" 
                />
            </div>
        </div>
    </div>

    {/* ===================================================================== */}
    <div className="main">
        <div className="main__team home__sub__testimony__about home__sub__testimony about__toward__container about__toward__container__sub">
            <div className="main__team__image home__sub__testimony__image about__toward__image__sub">
                <img 
                    src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1706204908/sc1kgtuvabgwoskr6lhz.jpg" 
                    alt="" 
                />
            </div>
            <div className="main__team__description home__sub__testimony__description about__toward__description__sub">
                <h2 id='united__in__resilience'>United in<br/>Resilience</h2>
                <h3>Embark with us on a healing
                    journey where each step is 
                    marked by the strength of
                    unity.
                    <br /><br />Resilience is our common
                    thread here. As a community,
                    we overcame challenges
                    together, sharing smiles and
                    victories.
                    <br /><br />
                    Our dedicated professionnals
                    guide this union towards a
                    flourishing future.
                    <br/><br />
                    Becouse together, we are
                    stronger, and within this
                    unity, resilience became a
                    collective path towards well-
                    being and healing.<br/>
                </h3>
            </div>
        </div>
        <FreeConsultationForm />
    </div>
</div>

/* =============================== Toward ================================= */
  )
}

export default TowardsCover