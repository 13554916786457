import React from 'react'
import FreeConsultationForm from '../../../../form/FreeConsultationForm'
import { HashLink as Link } from 'react-router-hash-link';

const SectionTestimonial = () => {
  return (
   /* ============================= Testimonial ================================= */

<div className="testimonial">
    <div className="main">
        <div className="main__header home__section__testimonial">
            <h1>Client Testimonials</h1>
            <div className="liatestimons">
                <div className="liatestimons__image home__section__testimonial__card">
                    <p>"Life-Changing therapy! Overcome<br/>
                        struggles, found balance, and now<br/>
                        thriving. Grateful for transformative<br/>
                        support!"
                    </p>
                <div className="liatestimons__image__single">
                    <img 
                        src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1707430652/Nouveau_projet_3_jsbyfk.png" 
                        alt="jp "
                    />
                    <h4>Eden Bothman<br/>
                        Photographer<br/>
                        CEO ShineStyle
                    </h4>
                </div>
            </div>

            <div className="liatestimons__image home__section__testimonial__card">
                <p>"Therapy here was my lifeline. Tailored<br/>
                    approach, safe space, and now I'm<br/>
                    thriving. Thank you for the unwavering<br/>
                    sopport!"
                </p>
                <div className="liatestimons__image__single">
                    <img src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1707430652/Nouveau_projet_2_gcpecc.png" alt=""/>
                    <h4>
                        Terry Backer<br/>
                        Musicien / Youtuber
                    </h4>
                </div>
            </div>

            <div className="liatestimons__image home__section__testimonial__card">
                <p>
                    "Transformative therapy! Professional'<br/>
                    caring, and guided me to a brighter<br/>
                    future. My life has truly changed."
                </p>
                <div className="liatestimons__image__single">
                    <img src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1707430652/Nouveau_projet_1_o8mc0x.png" alt=""/>
                    <h4>
                        Karine TucKer<br/>
                        Florist<br/>
                        CEO Whiteflowers
                    </h4>
                </div>
            </div>
        </div>
        <FreeConsultationForm />
    </div>
    
        <div className="main__team home__sub__testimony">
            <div className="main__team__description home__sub__testimony__description__new home__sub__testimony__description">
                <h2>New Dawn, Fresh Start</h2>
                <h3>
                    Embrace the opportunity for a fresh
                    <br/>start. Your journey to fulfilling life
                    <br/>begins here.
                </h3>
                <Link to='/ourteam/#'>
                    <button 
                        className="home__content__button home__content__button__blue__our__team button__our__team"
                    >
                        Our Team
                    </button>
                </Link>
                <img 
                    src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1705872503/WhatsApp_Image_2024-01-15_at_09.14.46_klkslx.jpg" 
                    alt="logo"
                />
            </div>
            <div className="main__team__image home__sub__testimony__image">
                <img src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1705952553/pexels-vlad-chet%CC%A6an-2923156_oc02ur.jpg" alt=""/>
            </div>
        </div>
    </div>
</div>

/* =============================== Testimonial ================================= */
  )
}

export default SectionTestimonial