import React from 'react'

const Anxiete = () => {
  return (
           /* ============================= Anxiete ================================= */
<div className="anxiete anxiete__laptop">
    <div className="program__reconciliation__text__sub">
        <div className='program__reconciliation__text__sub'>
            <p>
                Our anxiety program is designed to provide holistic support for those seeking<br/>to overcome the challenges of anxiety and regain a balanced life. 
            </p>
            <p>
                The experienced professionals on our team collaborate with you to develop a personalized treatment plan, combining innovative therapeutic approaches with proven techniques.
            </p>
        </div>  
    </div>        

    <div className="program__reconciliation">
        <div className='program__reconciliation__text__list__left program__reconciliation__text__list__left__tablet'>
            <h4>Key Components:</h4>
            <div>
                <h4>1.Comprehensive Assessment: </h4>
                <p>
                    We start with a thorough evaluation to understand the specific factors contributing to anxiety, allowing for a targeted approach.
                </p>
            </div>
            
            <h4>2.Individual Therapies: </h4>
            <p>
                Individual sessions offer a confidential space to explore anxiety triggers, develop management strategies, and enhance emotional resilience.
            </p>
            <h4>3.Group Therapies: </h4>
            <p>
                Group sessions provide a supportive environment, encouraging the sharing of experiences and building connections with others facing similar situations.
            </p>
            <h4>4.Stress Management Techniques: </h4>
            <p>
                Practical tools and relaxation techniques are integrated to help manage anxiety symptoms on a daily basis.
            </p>
            <h4>5.Wellness Promotion: </h4>
            <p>
                We emphasize promoting a healthy lifestyle, including nutrition, exercise, and quality sleep, to enhance overall well-being.
            </p>
            <h4>6.Long-Term Prevention Strategies: </h4>
            <p>
                Our program aims to provide sustainable skills for anxiety management, fostering an enriching life in the long run.
            </p>
        </div>  
        <div className="program__reconciliation__image program__reconciliation__image__anxiete program__reconciliation__image__anxiete__tablet program__reconciliation__image__anxiete__tablet__mini">
            <img 
                src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1706645108/pexels-mart-production-8458945_ig5aec.jpg" 
                alt="Anxiete"
            />
        </div>
    </div>    

    <div className="program__reconciliation__text__sub">
        <div className='program__reconciliation__text__sub'>
            <p>
                Together, we weave a network of hope and understanding, guiding each<br/>individual toward light and rebirth after dark times. You are not alone on this<br/>journey – let us accompany you towards a brighter life.
            </p>
        </div>  
    </div>            
</div>

/* =============================== Anxiete ================================= */
  )
}

export default Anxiete