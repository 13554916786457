import React from 'react'

export const Frustration = () => {
  return (
         /* ============================= Mental Health ================================= */
<div className="negative mental__health">
    <div className="program__reconciliation__text__sub">
        <div className='program__reconciliation__text__sub'>
            <p>
                Our Frustration Program is designed to help individuals transform frustration<br/>
                into personal triumphs, fostering empowerment and success.
            </p>
            <br/>
            <p>
                Led by experienced professionals, we offer evidence-based interventions to<br/>
                address frustration and pave the way towards resilience and achievement.
            </p>
        </div>  
    </div>        

    <div className="program__reconciliation">
        <div className='program__reconciliation__text__anxiete__text__right program__reconciliation__text__list__left'>
            <h4>Key Components:</h4>
            <div>
                <h4>1.Comprehensive Frustration Assessment:</h4>
                <p>
                    We start with a thorough evaluation to understand the specific sources of frustration, tailoring our approach to your unique situation.
                </p>
            </div>
            
            <h4>2.Cognitive Restructuring Techniques:</h4>
            <p>
                Evidence-based strategies are employed to reframe negative thought patterns and cultivate a positive mindset in the face of frustration.
            </p>
            <h4>3.Emotional Regulation Skills:</h4>
            <p>
                Learning effective techniques to manage and regulate emotions associated with frustration, promoting a sense of calm and control.
            </p>
            <h4>4.Communication and Assertiveness Training:</h4>
            <p>
                Building skills to express needs and concerns assertively, fostering better interpersonal relationships and reducing frustration.
            </p>
            <h4>5.Mindfulness Practices:</h4>
            <p>
                Incorporating mindfulness techniques to enhance self-awareness and manage frustration in a mindful and constructive manner.
            </p>
            <h4>6.Supportive Counseling:</h4>
            <p>
                Individual counseling sessions provide a safe space to explore the roots of frustration, discuss coping strategies, and work towards a more fulfilling life.
            </p>
        </div>  
        <div className="program__reconciliation__image__mental__health__cover__right program__reconciliation__image program__reconciliation__image__mentalhealth">
            <img 
                src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1706645106/pexels-andrea-piacquadio-3777572_osoyux.jpg" 
                alt="Mental Health"
            />
        </div>
    </div>    

    <div className="program__reconciliation__text__sub">
        <div className='program__reconciliation__text__sub'>
            <h5>Embark on the journey from frustration to triumph with us.</h5>
            <p>
                Let us guide you toward a life where frustration becomes a stepping stone
                <br/>to empowerment and success.
            </p>
        </div>  
        <div className="program__reconciliation__image program__reconciliation__image__mentalhealth program__reconciliation__image__anxiete__text__sub">
            <img 
                src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1706645107/pexels-thirdman-7659873_rf96f8.jpg" 
                alt="Frustration"
            />
        </div>
    </div>            
</div>

/* =============================== Mental Health ================================= */
  )
}
