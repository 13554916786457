import React from 'react'

const InspiringVoices = () => {
  return (
        /* ============================= Inspiring Voices ================================= */
    <div className="program about__inspiring__voices__container about__inspiring__voices__container__blue">
        <div className="program__reconciliation about__inspiring__voices__container__blue__reverse">
            <div className="program__reconciliation__text about__inspiring__voices__text">
                <h1 id='inspiring__voices'>Inspiring Voices</h1>
                <p>Our clinic goes beyond individual treatment</p>

                <p>We believe in the power of community and
                   host therapeutic gatherings, where our
                   patients can exchange, testify, and share
                   their experiences in a supportive environment.
                </p>

                <p>These meetings, guided by our seasoned
                    professionals, provide a safe space where
                    everyone finds understanding and support.
                    Together, with our dedicated physicians,
                    we shape a collective path to healing
                </p>

                <p>In solidarity, exchang, and medicol
                   expertise, we build a future where
                   everyone can thrive. Be part of this unique
                   therapeutic experience, where unity
                   becomes the catalyst for healing
                </p>
            </div>
            <img className="program__reconciliation__image about__inspiring__voices__image" src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1706204917/v11fuhyvhxuvbuz0qqmp.jpg" alt="Healing" />    
        </div>            
    </div>

/* =============================== Inspiring Voices ================================= */
  )
}

export default InspiringVoices

