import React from 'react'

const MentalHealth = () => {
  return (
     /* ============================= Mental Health ================================= */
<div className="negative mental__health">
    <div className="program__reconciliation__text__sub">
        <div className='program__reconciliation__text__sub'>
            <p>
                Our Mental Health Program is designed to provide comprehensive support for<br/> individuals seeking lasting well-being. 
            </p>
            <br/>
            <p>
                Guided by dedicated professionals, we offer a range of evidence-based<br/> interventions to address mental health challenges and promote a thriving life.
            </p>
        </div>  
    </div>        

    <div className="program__reconciliation">
        <div className='program__reconciliation__text__anxiete__text__right program__reconciliation__text__list__left'>
            <h4>Key Components:</h4>
            <div>
                <h4>1.Holistic Mental Health Assessment:</h4>
                <p>
                    We begin with a thorough assessment to understand individual mental health needs comprehensively.
                </p>
            </div>
            
            <h4>2.Individualized Therapy Plans:</h4>
            <p>
                Tailored therapeutic interventions are crafted to address specific mental health concerns, ensuring a personalized approach.
            </p>
            <h4>3.Counseling and Psychotherapy:</h4>
            <p>
                Our program includes counseling and psychotherapy sessions to explore thoughts, emotions, and behavioral patterns in a supportive environment.
            </p>
            <h4>4.Wellness Promotion:</h4>
            <p>
                We emphasize lifestyle factors such as nutrition, exercise, and sleep, fostering holistic well-being.
            </p>
            <h4>5.Mindfulness and Stress Reduction:</h4>
            <p>
                Techniques for mindfulness and stress reduction are integrated to enhance mental resilience and coping skills.
            </p>
            <h4>6.Peer Support Groups:</h4>
            <p>
                Creating a community for mutual support, individuals can share experiences and insights, fostering a sense of connection.
            </p>
        </div>  
        <div className="program__reconciliation__image__mental__health__cover__right program__reconciliation__image program__reconciliation__image__mentalhealth">
            <img 
                src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1706645107/pexels-cottonbro-studio-4327133_ab5gwp.jpg" 
                alt="Mental Health"
            />
        </div>
    </div>    

    <div className="program__reconciliation__text__sub">
        <div className='program__reconciliation__text__sub'>
            <h5>Join us to illuminate your path towards positivity.</h5>
            <p>Transform negativity into a driving force for an enriching life.</p>
        </div>  
        <div className="program__reconciliation__image program__reconciliation__image__mentalhealth program__reconciliation__image__anxiete__text__sub">
            <img 
                src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1706645109/pexels-andrea-piacquadio-864939_lbj9mv.jpg" 
                alt="Mental Health Work"
            />
        </div>
    </div>            
</div>

/* =============================== Mental Health ================================= */
  )
}

export default MentalHealth