import React from 'react'
import SectionProgramCard from '../component/general/section/sectionProgramCard/SectionProgramCard'
import FreeConsultationForm from '../component/general/form/FreeConsultationForm'
import Depression from '../component/general/section/pages/services/sectionRightCover/Depression'
import Anxiete from '../component/general/section/pages/services/sectionLeftCover/Anxiete'
import MentalHealth from '../component/general/section/pages/services/sectionRightAndBottomCover/MentalHealth'
import { Fear } from '../component/general/section/pages/services/sectionRightAndBottomCover/Fear'
import Negative from '../component/general/section/pages/services/sectionLeftAndBottomCover/Negative'
import { Frustration } from '../component/general/section/pages/services/sectionLeftAndBottomCover/Frustration'



const Services = () => {
  return (
    <div>
      <div id='services'>
        <section>
          {/* ================================================= */}
          {/* ==================== Our Mission ===================== */}
          {/* ================================================= */}
          <div className="home__section our__team__section">
            <div class="mission home__section__mission">
                <div class="mission-frist home__section__mission__content about__page__mission__title">
                    <h1>Programs</h1>
                    <p className='page__title__text'>    
                        Personalized Care, Tailored Well-being: <br/>
                        Your Journey to Healing Begins Here.
                    </p>
                </div>
            </div>
          </div>
        </section>

        <section className='section__program'>
          {/* ================================================= */}
          {/* ================ Section Programs Card =================== */}
          {/* ================================================= */}
          <div className='services__program__card__background'>
            <SectionProgramCard />
          </div>
          <FreeConsultationForm />
        </section>

        <section id='program__depression' className='depression' >
          {/* ================================================= */}
          {/* ==================== Depression ===================== */}
          {/* ================================================= */}
          <div className="home__section our__team__section">
            <div class="mission home__section__mission">
                <div class="mission-frist home__section__mission__content about__page__mission__title">
                    <h1 id='depression__program'>Depression</h1>
                    <p className='page__title__text'>    
                      Rising from the Shadows: Our Depression Program,<br/>
                      Guiding Light on Your Path to Recovery.
                    </p>
                </div>
            </div>
          </div>

          {/* ================================================= */}
          {/* ================ Depression Card =================== */}
          {/* ================================================= */}
          <div className='services__program__card__background'>
            <Depression />
          </div>
          <FreeConsultationForm/>
        </section>

        <section className='anxiete' id='program__anxiete'>
          {/* ================================================= */}
          {/* ==================== Anxiete ===================== */}
          {/* ================================================= */}
          <div className="home__section our__team__section">
            <div class="mission home__section__mission">
                <div class="mission-frist home__section__mission__content about__page__mission__title">
                    <h1 id='anxiete__program'>Anxiete</h1>
                    <p className='page__title__text'>    
                      Illuminate Your Path: Our Anxiety Program,<br/>
                      a Wellspring of Serenity for Every Stride.
                    </p>
                </div>
            </div>
          </div>

          {/* ================================================= */}
          {/* ================ Anxiete Card =================== */}
          {/* ================================================= */}
          <div className='services__program__card__background'>
            <Anxiete />
          </div>
          <FreeConsultationForm/>
        </section>

        <section className='negative' id='program__negative'>
          {/* ================================================= */}
          {/* ==================== Negative ===================== */}
          {/* ================================================= */}
          <div className="home__section our__team__section">
            <div class="mission home__section__mission">
                <div class="mission-frist home__section__mission__content about__page__mission__title">
                    <h1 id='negative__program'>Negative</h1>
                    <p className='page__title__text'>    
                      Beyond Shadows: Our Negativity Program,<br/>
                      Guiding You Towards the Light of Positivity.
                    </p>
                </div>
            </div>
          </div>

          {/* ================================================= */}
          {/* ================ Negative =================== */}
          {/* ================================================= */}
          <div className='services__program__card__background'>
            <Negative />
          </div>
          <FreeConsultationForm/>
        </section>

        <section className='mental__health' id='program__mental__health'>
          {/* ================================================= */}
          {/* ==================== Mental Health ===================== */}
          {/* ================================================= */}
          <div className="home__section our__team__section">
            <div class="mission home__section__mission">
                <div class="mission-frist home__section__mission__content about__page__mission__title">
                    <h1 id='mentalhealth__program'>Mental Health</h1>
                    <p className='page__title__text'>    
                      Healing Minds, Enriching Lives:<br/>
                      Our Mental Health Program for Lasting Well-being.
                    </p>
                </div>
            </div>
          </div>

          {/* ================================================= */}
          {/* ================ Negative =================== */}
          {/* ================================================= */}
          <div className='services__program__card__background'>
            <MentalHealth />
          </div>
          <FreeConsultationForm/>
        </section>

        <section className='fear' id='program__fear'>
          {/* ================================================= */}
          {/* ==================== Fear ===================== */}
          {/* ================================================= */}
          <div className="home__section our__team__section">
            <div class="mission home__section__mission">
                <div class="mission-frist home__section__mission__content about__page__mission__title">
                    <h1 id='fear__program'>Fear</h1>
                    <p className='page__title__text'>    
                      Conquering Fears, Embracing Freedom:<br/> 
                      Our Fear Program, Guiding You to Courage and Liberation.
                    </p>
                </div>
            </div>
          </div>

          {/* ================================================= */}
          {/* ================ Negative =================== */}
          {/* ================================================= */}
          <div className='services__program__card__background'>
            <Fear/>
          </div>
          <FreeConsultationForm/>
        </section>

        <section className='frustation' id='program__frustration'>
          {/* ================================================= */}
          {/* ==================== Frustration ===================== */}
          {/* ================================================= */}
          <div className="home__section our__team__section frustration__laptop">
            <div class="mission home__section__mission">
                <div class="mission-frist home__section__mission__content about__page__mission__title">
                    <h1 id='frustration__program'>Frustration</h1>
                    <p className='page__title__text'>    
                      Turning Frustration into Triumph: Our Frustration Program,<br/>
                      Guiding You to Empowerment and Success.
                    </p>
                </div>
            </div>
          </div>

          {/* ================================================= */}
          {/* ================ Negative =================== */}
          {/* ================================================= */}
          <div className='services__program__card__background'>
            <Frustration/>
          </div>
          <FreeConsultationForm/>
        </section>

        <section className='section__before__footer' id='program__section__before__footer'>
          {/* ================================================= */}
          {/* ==================== Conclusion ===================== */}
          {/* ================================================= */}
          <div className="home__section our__team__section before__footer__laptop">
            <div class="mission home__section__mission">
              <div class="mission-frist home__section__mission__content about__page__mission__title">
                <p className='page__title__text'>    
                  Whether facing anxiety, depression, negativity, fear, or frustration, our dedicated team is here for you. 
                </p>
                <p className='page__title__text'>    
                  Together, we'll create a personalized path, celebrating your successes, overcoming obstacles, and guiding you towards a life of resilience, positivity, and personal fulfillment.
                </p>
                <p className='page__title__text'>    
                  The invitation is extended. 
                  Your journey to improved mental health begins here. 
                  Join us to illuminate your path to well-being
                </p>
                <div className="section__before__footer__cover">
                  <img 
                    src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1706645106/medium-shot-community-meeting_bfuugo.jpg" 
                    alt="Conclusion"
                  />
                </div>
              </div>    
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Services