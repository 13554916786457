import React from 'react'

const ProfileCard = () => {
  return (
    /* ============================= Mental Health ================================= */
    <div className="testimonial">
        <div className="main">
            <div className="main__header home__section__testimonial profile__card__style__container">
                {/* Line 1 */}
                <div className="liatestimons profile__card__style__line">
                    {/* Card 1 */}
                    <div className="home__section__testimonial__card profile__card__style">
                        <img 
                            src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1706645110/pexels-ono-kosuki-5647292_idcfao.jpg" 
                            alt="photo "
                        />  
                        <h4>Philippe YEPMO</h4>
                        <p>CEO - BHT- BEHAVIORAL HEALTH TECHNICIAN</p>
                    </div>

                    {/* Card 2 */}
                    <div className="home__section__testimonial__card profile__card__style">
                        <img 
                            src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1706460246/jqvhspw4mg7wcaiczgu0.jpg" 
                            alt="photo "
                        />  
                        <h4>Xiu CHAN</h4>
                        <p>BHT- BEHAVIORAL HEALTH TECHNICIAN</p>
                    </div>

                    {/* Card 3 */}
                    <div className="home__section__testimonial__card profile__card__style">
                        <img 
                            src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1706460242/ufeypjshoecwbxks8zkt.jpg" 
                            alt="photo "
                        />  
                        <h4>William CARTER</h4>
                        <p>THERAPIST</p>
                    </div>

                    {/* Card 4 */}
                    <div className="home__section__testimonial__card profile__card__style">
                        <img 
                            src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1706645110/pexels-antoni-shkraba-5215024_uai8px.jpg" 
                            alt="photo "
                        />  
                        <h4>Ivy SPENCER</h4>
                        <p>BHT- BEHAVIORAL HEALTH TECHNICIAN</p>
                    </div>
                </div>
                {/* Line 2 */}
                <div className="liatestimons profile__card__style__line">
                    {/* Card 1 */}
                    <div className="home__section__testimonial__card profile__card__style">
                        <img 
                            src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1706645109/pexels-domineves-anthony-12495583_jojcjw.jpg" 
                            alt="photo "
                        />  
                        <h4>Tamia ALADE</h4>
                        <p>THERAPIST</p>
                    </div>

                    {/* Card 2 */}
                    <div className="home__section__testimonial__card profile__card__style">
                        <img 
                            src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1706645109/pexels-carmel-nsenga-18788957_qreawe.jpg" 
                            alt="photo "
                        />  
                        <h4>Alicia JONES</h4>
                        <p>THERAPIST</p>
                    </div>

                    {/* Card 3 */}
                    <div className="home__section__testimonial__card profile__card__style">
                        <img 
                            src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1706645110/pexels-karolina-grabowska-5206931_wzo3kw.jpg" 
                            alt="photo "
                        />  
                        <h4>Zineb AOUAR</h4>
                        <p>PSYCHIATRIST NURSE</p>
                    </div>

                    {/* Card 4 */}
                    <div className="home__section__testimonial__card profile__card__style">
                        <img 
                            src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1706645109/pexels-laura-james-6098047_s5ziy3.jpg" 
                            alt="photo "
                        />  
                        <h4>Aissa DIABY</h4>
                        <p>PSYCHIATRIST NURSE</p>
                    </div>
                </div>
                {/* Line 3 */}
                <div className="liatestimons profile__card__style__line">
                    {/* Card 1 */}
                    <div className="home__section__testimonial__card profile__card__style">
                        <img 
                            src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1706645109/pexels-mikhail-nilov-6740087_ftlhor.jpg" 
                            alt="photo "
                        />  
                        <h4>Victoria GARCIA</h4>
                        <p>MENTAL COACH<br/>THERAPIST</p>
                    </div>

                    {/* Card 2 */}
                    <div className="home__section__testimonial__card profile__card__style">
                        <img 
                            src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1706645110/pexels-andrew-personal-training-733500_w1vg7c.jpg" 
                            alt="photo "
                        />  
                        <h4>Simon NGANNOU</h4>
                        <p>FITNESS COACH</p>
                    </div>

                    {/* Card 3 */}
                    <div className="home__section__testimonial__card profile__card__style">
                        <img 
                            src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1706645110/pexels-vanessa-garcia-6325958_mftxmw.jpg" 
                            alt="photo "
                        />  
                        <h4>Rivaldo DE SANTOS</h4>
                        <p>MENTAL COUNSELOR</p>
                    </div>

                    {/* Card 4 */}
                    <div className="home__section__testimonial__card profile__card__style">
                        <img 
                            src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1706645110/pexels-sora-shimazaki-5668876_vj5j1a.jpg" 
                            alt="photo "
                        />  
                        <h4>Sonia CAMPBELL</h4>
                        <p>SECRETARY</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    /* ============================= Mental Health ================================= */
  )
}

export default ProfileCard