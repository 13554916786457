import React from 'react'
import IconSection from '../component/general/section/pages/home/iconSection/IconSection'
import Section4Card from '../component/general/section/pages/home/section4Card/Section4Card'
import ProgramCover from '../component/general/section/pages/home/sectionRightCover/ProgramCover'
import SectionProgramCard from '../component/general/section/sectionProgramCard/SectionProgramCard'
import SectionTestimonial from '../component/general/section/pages/home/sectionTestimonial/SectionTestimonial'
import { HashLink as Link } from 'react-router-hash-link';


const Home = () => {
  return (
    <div>
      <div className='home__banner'>
      {/* =================== Cover Section =================== */}
        <section 
          className='cover home__cover home__cover'
        >
          {/* ================================================= */}
          {/* ================== Background =================== */}
          {/* ================================================= */}
            <img 
            className="home__content__image"
            src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1705882433/young-woman-attending-group-therapy-looking-camera_qziqef.jpg"
            alt="Genesis Cover"
          />

          {/* ================================================= */}
          {/* ==================== Cover ===================== */}
            {/* ================================================= */}
          <div className="home__content home__content__cover__infos__container">
            {/* Title */}
            <div className="home__content__title">
                <h1>A haven of peace for 
                      <br/>your inner healing
                </h1>
            </div>
            {/** Slogan */}
            <div className="home__content__slogan">
              <p>Welcome to our clinic, a haven of well-being
                <br/>dedicated to your inner healing.
              </p>
            </div>
            {/* Button */}
            <div className="home__content__button home__content__button__blue__cover">
              <Link to='/home#icon__section__section'>
                <button className="home__content__button__blue">SEE MORE</button>
              </Link>  
            </div>
          </div>
        </section>
      </div>
      <section className='section home__section__cover__end'>
      {/* ================================================= */}
      {/* ==================== Icons ====================== */}
      {/* ================================================= */}
        <div id='icon__section__section' className="home__section">
          <IconSection />
        </div>
      </section>

      <section>
        {/* ================================================= */}
        {/* ================ Section Card =================== */}
        {/* ================================================= */}
        <div className="home__section">
          <Section4Card />
        </div>
      </section>

      <section>
         {/* ================================================= */}
        {/* ================ Section Right Cover =================== */}
        {/* ================================================= */}
        <div>
          <ProgramCover />
        </div>
        </section>

      <section>
         {/* ================================================= */}
        {/* ================ Section Programs Card =================== */}
        {/* ================================================= */}
        <div>
          <SectionProgramCard />
        </div>
      </section>

      <section>
         {/* ================================================= */}
        {/* ================ Section Programs Card =================== */}
        {/* ================================================= */}
        <div>
          <SectionTestimonial />
        </div>
      </section>

      <section>
            {/* ================================================= */}
            {/* ==================== Our Mission ===================== */}
            {/* ================================================= */}
            <div className="home__section">
                <div class="mission home__section__mission">
                    <div class="mission-frist home__section__mission__content">
                        <h1>Our mission</h1>
                        <p>
                            Help you rediscover the joy of<br/>living and awaken the happines with in you<br/>
                        </p>
                    </div>
                </div>
            </div>
            <div className="home__section">
                {/* Button */}
                <div className="home__content__button home__content__button__blue__cover home__content__button__blue__about home__content__button__blue__about__button">
                    <Link to='/about/#'>
                      <button className="home__content__button__blue">About Us</button>
                    </Link>              
                </div>
                <div class="mission-second home__content__cover__about home__content__cover__about__image home__content__cover__about__image__mobile">
                    <img src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1705952553/pexels-andre-furtado-1429395_qbqxid.jpg" alt="" />
                </div>
            </div>
      </section>
    </div>
    
    
   
    
  )
}

export default Home