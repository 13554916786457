import React, { useRef } from 'react'
import Navbar from '../nav/Navbar'

const Header = () => {
  return (
    <div className='fixed'>
      {/* ================================================= */}
      {/* ==================== Navbar ===================== */}
      {/* ================================================= */}
      <Navbar />
    </div>
  )
}

export default Header