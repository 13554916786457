import React from 'react'
import ProfileCard from '../component/general/card/ProfileCard'
import FreeConsultationForm from '../component/general/form/FreeConsultationForm'
import CoverClinic from '../component/general/section/pages/our__team/sectionSubCover/CoverClinic'



const OurTeam = () => {
  return (
    <div id='ourteam'>
        <section>
        {/* ================================================= */}
        {/* ==================== Our Team ===================== */}
        {/* ================================================= */}
        <div className="home__section our__team__section">
            <div class="mission home__section__mission">
                <div class="mission-frist home__section__mission__content about__page__mission__title">
                    <h1>Our Team</h1>
                    <p className='page__title__text'>    
                      United by Devotion, Guided by Expertise.<br/>
                      Explore the strength of our team dedicated to your well-being.
                    </p>
                </div>
            </div>
        </div>

        {/* ================================================= */}
        {/* ================ Our Team =================== */}
        {/* ================================================= */}
        <div className='services__program__card__background'>
          <ProfileCard />
        </div>
        <FreeConsultationForm />        
      </section>

      <section>
        {/* ================================================= */}
        {/* ================ Cover Clinic =================== */}
        {/* ================================================= */}
        <div className='services__program__card__background'>
          <CoverClinic />
        </div>
      </section>
    </div>
  )
}

export default OurTeam