import React from 'react'
import Header from '../component/general/header/Header';
import Footer from '../component/general/footer/Footer';
import Routers from '../routes/Routers';

const Layout = () => {
  return (
    <>
        <Header />
        <index>
            <Routers />
        </index>
        <Footer />
    </>
  );
};

export default Layout