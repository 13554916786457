import React from 'react'

export const Fear = () => {
  return (
       /* ============================= Fear ================================= */
<div className="Fear">
    <div className="program__reconciliation__text__sub">
        <div className='program__reconciliation__text__sub'>
            <p>
                Our Fear Program is crafted to empower individuals in overcoming fears, allowing<br/> for the experience of freedom and courage. 
            </p>
            <br/>
            <p>
                Led by skilled professionals, we provide evidence-based interventions to address<br/> various fears and facilitate a journey towards resilience and liberation.
            </p>
        </div>  
    </div>        

    <div className="program__reconciliation">
        <div className="program__reconciliation__image program__reconciliation__image__anxiete program__reconciliation__image__anxiete__cover__left">
            <img 
                src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1707243644/wepik-export-20240206182031xsB2_b9eyk1.jpg" 
                alt="Fear"
            />
        </div>
        <div className='program__reconciliation__text__anxiete__text__right program__reconciliation__text__list__left'>
            <h4>Key Components:</h4>
            <div>
                <h4>1.Comprehensive Fear Assessment:</h4>
                <p>
                    We begin with a thorough assessment to understand the specific fears and triggers, tailoring our approach to your unique needs.
                </p>
            </div>
            
            <h4>2.Cognitive-Behavioral Interventions:</h4>
            <p>
                Evidence-based techniques from Cognitive-Behavioral Therapy (CBT) are employed to reframe negative thought patterns and behaviors associated with fear.
            </p>
            <h4>3.Exposure Therapy:</h4>
            <p>
                Gradual exposure to feared situations, done in a supportive and controlled environment, helps individuals build resilience and diminish fear responses.
            </p>
            <h4>4.Mindfulness and Relaxation Techniques:</h4>
            <p>
                Incorporating mindfulness and relaxation practices to manage anxiety and promote a calm state of mind.
            </p>
            <h4>5.Supportive Counseling:</h4>
            <p>
                Individual counseling sessions provide a safe space to explore the roots of fear, discuss coping strategies, and work towards overcoming specific fears.
            </p>
            <h4>6.Empowerment Workshops:</h4>
            <p>
                Group workshops foster a supportive community, allowing individuals to share experiences and strategies for overcoming fears.
            </p>
        </div>  
    </div>    

    <div className="program__reconciliation__text__sub">
        <div className='program__reconciliation__text__sub'>
            <h5>Embark on the journey to fearlessness with us.</h5>
            <p>
                Let us guide you toward a life where fear no longer holds you back,
                <br />and courage becomes your companion.
            </p>
        </div>  
        <div className="program__reconciliation__image program__reconciliation__image__fear program__reconciliation__image__cover__sub program__reconciliation__image__anxiete program__reconciliation__image__anxiete__text__sub">
            <img 
                src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1706645106/pexels-cottonbro-studio-4101187_yjdht7.jpg" 
                alt="Fear Hope"
            />
        </div>
    </div>            
</div>

/* =============================== Fear ================================= */
  )
}
