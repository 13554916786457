import React from 'react'
import FreeConsultationForm from '../../../../form/FreeConsultationForm'
import { HashLink as Link } from 'react-router-hash-link';


const ContactCover = () => {
    const phoneNumber = '+1 480-597-6454';
    const phoneNumbertwo = '+1 480-264-2922';
  return (
    /* ============================= Contact Cover ================================= */
<div id='map' className="main">
        <div className="main__team home__sub__testimony__about home__sub__testimony about__toward__container about__toward__container__sub contact__container">
            <div className="main__team__image home__sub__testimony__image about__toward__image__sub contact__cover">
                <img 
                    src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1706381156/nrczkyqf1cuvfopn1pcm.jpg" 
                    alt="" 
                />
            </div>
            <div className="main__team__description home__sub__testimony__description about__toward__description__sub contact__text">
                <h2>Contact</h2>
                <Link to={`tel:${phoneNumber}`} className="link">{phoneNumber}</Link>
                <Link to={`tel:${phoneNumbertwo}`} className="link">{phoneNumbertwo}</Link>
                <br/>
                <Link to="mailto:contact@genesis.com" className="link">contact@genesisoutpatient.com</Link>
                <br/>
                <h4>GENESIS OUTPATIENTE LLC</h4>
                <h4>7303 E MAIN STREET. </h4>
                <h4>SUITE 106</h4>
                <h4>MESA, ARIZONA 85208</h4>
            </div>
        </div>
        <FreeConsultationForm />
    </div>

/* =============================== Contact Cover ================================= */
  )
}

export default ContactCover