import React from 'react'

const CarteCover = () => {
    
  return (
        /* ============================= Contact Cover ================================= */
<div id='contact' className="main">
        <div className="main__team home__sub__testimony__about home__sub__testimony about__toward__container about__toward__container__sub contact__map__container">
            <img 
                className='logo'
                src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1705872503/WhatsApp_Image_2024-01-15_at_09.14.46_klkslx.jpg" 
                alt="logo"
            /> 
            <div className="main__team__image home__sub__testimony__image about__toward__image__sub contact__cover contact__map__image">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3330.327515861016!2d-111.6761359245573!3d33.41470467340369!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x872bafe6ea01af4b%3A0x564570c748a6ffda!2s7303%20E%20Main%20St%20%23106%2C%20Mesa%2C%20AZ%2085207%2C%20USA!5e0!3m2!1sen!2sfr!4v1707158119227!5m2!1sen!2sfr" width="800" height="600" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    </div>

/* =============================== Contact Cover ================================= */
  )
}

export default CarteCover