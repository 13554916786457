import React from 'react'
import { HashLink as Link } from 'react-router-hash-link';


const Section4Card = () => {
  return (
        /* ============================= We want to talk with you ================================= */
    <div className="cards__blue__background">
        <div className="cards__blue__background__padding home__content__card__header">
            <div className="home__content__title home__content__card__text">
                <h2>WE WANT TO WALK WITH YOU</h2>
            </div>
            <div 
                className="home__content__slogan home__content__card__text home__content__card__slogan"
            >
                <p>Caring services to soothe your journey towards well-being</p>
            </div>
        </div>
    
        {/* =============================== Desktop ================================= */}
        <div className="cards cards__blue__background card__blue__column cards__desktop">
            <div className="card card__blue__content">
                {/* Images */}
                <div className="card__section card__width home__content__card__image">
                    <img 
                        className="card__image"
                        src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1705952557/pexels-alex-green-5699423_x1xu0n.jpg" 
                        alt="calm" 
                    />
                </div>
                <div className="card__section card__width home__content__card__image">
                    <img 
                        className="card__image"
                        src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1705952559/pexels-cottonbro-studio-4101187_effnko.jpg" 
                        alt="fredoom" 
                    />
                </div>
                <div className="card__section card__width home__content__card__image">
                    <img
                        className="card__image" 
                        src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1705952556/pexels-alex-green-5700164_z9akvb.jpg" 
                        alt="united" 
                    />
                </div>
                <div className="card__section card__width home__content__card__image">
                    <img 
                        className="card__image"
                        src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1705952556/group-diverse-people-holding-hands-up-air_dcywuk.jpg" 
                        alt="voice" 
                    />
                </div>
            </div>
            <div className="card card__blue__content">
                {/* Title */}
                <div className="home__content__card__title">
                    <h3 className="card__width">Assured Calm</h3>
                    <h3 className="card__width">Toward Enlightened<br/>Freedom</h3>
                    <h3 className="card__width">United in Resilience</h3>
                    <h3 className="card__width">Inspiring Voices</h3>
                </div>
            </div>
            <div className="card card__blue__content">
                {/* Text */}
                <div className="home__content__card__text">
                    <p className="card__width">
                        Navigate through anxiety with<br/>serenity; your peace of mind<br/>begins here
                    </p>
                    <p className='card__width'>
                        Navigate through anxiety with
                        <br/>serenity; your peace of mind
                        <br/>begins here
                    </p>
                    <p className="card__width">
                        You're never alone: together,
                        <br/>stronger against the challenges.
                    </p>
                    <p className="card__width">
                        Sharing our stories, guiding
                        <br/> towards hope: Testimonies that
                        <br/> transform lives.
                    </p>
                </div>
            </div>
            <div className="card card__blue__content">
                {/* Button */}
                <div className="card__section card__width">
                    <Link to="/about#new__chapter__begins">
                        <button>SEE MORE</button>
                    </Link>
                </div>
                <div className="card__section card__width">
                    <Link to='/about#toward__healing__and__reconciliation'>
                        <button>SEE MORE</button>
                    </Link>
                </div>
                <div className="card__section card__width">
                    <Link to='/about#united__in__resilience'>
                        <button>SEE MORE</button>
                    </Link>
                </div>
                <div className="card__section card__width">        
                    <Link to='/about#inspiring__voices'>
                        <button>SEE MORE</button>
                    </Link>
                </div>
            </div>
        </div>

        {/* =============================== Tablet ================================= */}
        <div className="cards cards__blue__background card__blue__column cards__tablet">
            <div className="card card__blue__content">
                {/* Images */}
                <div className="card__section card__width home__content__card__image">
                    <img 
                        className="card__image"
                        src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1705952557/pexels-alex-green-5699423_x1xu0n.jpg" 
                        alt="calm" 
                    />
                </div>
                <div className="card__section card__width home__content__card__image">
                    <img 
                        className="card__image"
                        src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1705952559/pexels-cottonbro-studio-4101187_effnko.jpg" 
                        alt="fredoom" 
                    />
                </div>
            </div>
            <div className="card card__blue__content">
                {/* Title */}
                <div className="home__content__card__title">
                    <h3 className="card__width">Assured Calm</h3>
                    <h3 className="card__width">Toward Enlightened<br/>Freedom</h3>
                </div>
            </div>
            <div className="card card__blue__content">
                {/* Text */}
                <div className="home__content__card__text">
                    <p className="card__width">
                        Navigate through anxiety with<br/>serenity; your peace of mind<br/>begins here
                    </p>
                    <p className='card__width'>
                        Navigate through anxiety with
                        <br/>serenity; your peace of mind
                        <br/>begins here
                    </p>
                </div>
            </div>
            <div className="card card__blue__content">
                {/* Button */}
                <div className="card__section card__width">
                    <Link to="/about#new__chapter__begins">
                        <button>SEE MORE</button>
                    </Link>
                </div>
                <div className="card__section card__width">
                    <Link to='/about#toward__healing__and__reconciliation'>
                        <button>SEE MORE</button>
                    </Link>
                </div>
            </div>

            {/** Image Line 2 */}
            <div className="card card__blue__content">
                <div className="card__section card__width home__content__card__image">
                    <img
                        className="card__image" 
                        src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1705952556/pexels-alex-green-5700164_z9akvb.jpg" 
                        alt="united" 
                    />
                </div>
                <div className="card__section card__width home__content__card__image">
                    <img 
                        className="card__image"
                        src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1705952556/group-diverse-people-holding-hands-up-air_dcywuk.jpg" 
                        alt="voice" 
                    />
                </div>
            </div>
            {/** Title Line 2 */}
            <div className="card card__blue__content">
                <div className="home__content__card__title">
                    <h3 className="card__width">United in Resilience</h3>
                    <h3 className="card__width">Inspiring Voices</h3>
                </div>
            </div>
            {/** Text Line 2 */}
            <div className="card card__blue__content">
                <div className="home__content__card__text">
                    <p className="card__width">
                        You're never alone: together,
                        <br/>stronger against the challenges.
                    </p>
                    <p className="card__width">
                        Sharing our stories, guiding
                        <br/> towards hope: Testimonies that
                        <br/> transform lives.
                    </p>
                </div>
            </div>
             {/** Button Line 2 */}
             <div className="card card__blue__content">
                <div className="card__section card__width">
                    <Link to='/about#united__in__resilience'>
                        <button>SEE MORE</button>
                    </Link>
                </div>
                <div className="card__section card__width">        
                    <Link to='/about#inspiring__voices'>
                        <button>SEE MORE</button>
                    </Link>
                </div>
            </div>
        </div>

        {/* =============================== Mobile ================================= */}
        <div className="cards cards__blue__background card__blue__column cards__mobile">
            <div className="card card__blue__content">
                {/* Images */}
                <div className="card__section card__width home__content__card__image">
                    <img 
                        className="card__image"
                        src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1705952557/pexels-alex-green-5699423_x1xu0n.jpg" 
                        alt="calm" 
                    />
                    <div className="card card__blue__content">
                    {/* Title */}
                        <div className="home__content__card__title">
                            <h3 className="card__width">Assured Calm</h3>
                        </div>
                    </div>
                    <div className="card card__blue__content">
                    {/* Text */}
                        <div className="home__content__card__text">
                            <p className="card__width">
                                Navigate through anxiety with
                                <br/>serenity; your peace of mind
                                <br/>begins here
                            </p>
                        </div>
                    </div>
                    <div className="card card__blue__content">
                        {/* Button */}
                        <div className="card__section card__width">
                            <Link to="/about#new__chapter__begins">
                                <button>SEE MORE</button>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="card__section card__width home__content__card__image">
                    {/* Images */}
                    <img 
                        className="card__image"
                        src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1705952559/pexels-cottonbro-studio-4101187_effnko.jpg" 
                        alt="fredoom" 
                    />
                    <div className="card card__blue__content">
                        {/* Title */}
                        <div className="home__content__card__title">
                            <h3 className="card__width">Toward Enlightened<br/>Freedom</h3>
                        </div>
                    </div>
                    <div className="card card__blue__content">
                        {/* Text */}
                        <div className="home__content__card__text">
                            <p className='card__width'>
                                Navigate through anxiety with
                                <br/>serenity; your peace of mind
                                <br/>begins here
                            </p>
                        </div>
                    </div>
                    <div className="card card__blue__content">
                        {/* Button */}
                        <div className="card__section card__width">
                            <Link to='/about#toward__healing__and__reconciliation'>
                                <button>SEE MORE</button>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="card__section card__width home__content__card__image">
                    <img
                        className="card__image" 
                        src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1705952556/pexels-alex-green-5700164_z9akvb.jpg" 
                        alt="united" 
                    />
                    <div className="card card__blue__content">
                        {/* Title */}
                        <div className="home__content__card__title">
                            <h3 className="card__width">United in Resilience</h3>
                        </div>
                    </div>
                    <div className="card card__blue__content">
                        {/* Text */}
                        <div className="home__content__card__text">
                            <p className="card__width">
                                You're never alone: together,
                                <br/>stronger against the challenges.
                            </p>
                        </div>
                    </div>
                    <div className="card card__blue__content">
                        {/* Button */}
                        <div className="card__section card__width">
                            <Link to='/about#united__in__resilience'>
                                <button>SEE MORE</button>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="card__section card__width home__content__card__image">
                    <img 
                        className="card__image"
                        src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1705952556/group-diverse-people-holding-hands-up-air_dcywuk.jpg" 
                        alt="voice" 
                    />
                    <div className="card card__blue__content">
                        {/* Title */}
                        <div className="home__content__card__title">
                            <h3 className="card__width">Inspiring Voices</h3>
                        </div>
                    </div>
                    <div className="card card__blue__content">
                        {/* Text */}
                        <div className="home__content__card__text">
                            <p className="card__width">
                                Sharing our stories, guiding
                                <br/> towards hope: Testimonies that
                                <br/> transform lives.
                            </p>
                        </div>
                    </div>
                    <div className="card card__blue__content">
                        {/* Button */}
                        <div className="card__section card__width">
                            <Link to='/about#inspiring__voices'>
                                <button>SEE MORE</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
    /* =============================== We want to talk with you ================================= */
  )
}

export default Section4Card