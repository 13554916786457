import React from 'react'
import BeginingsCover from '../component/general/section/pages/about/sectionRightAndLeftCover/BeginingsCover'
import TowardsCover from '../component/general/section/pages/about/sectionRightAndLeftCoverSpace/TowardsCover'
import InspiringVoices from '../component/general/section/pages/about/sectionRightCover/InspiringVoices'
import SectionProgramCard from '../component/general/section/sectionProgramCard/SectionProgramCard'

const About = () => {
  return (
    <div id='about__page'>
      <section>
        {/* ================================================= */}
        {/* ==================== Our Mission ===================== */}
        {/* ================================================= */}
        <div className="home__section our__team__section">
            <div class="mission home__section__mission">
                <div class="mission-frist home__section__mission__content about__page__mission__title">
                    <h1>Our mission</h1>
                    <p>    
                      Help you rediscover the joy of
                      <br/>living and awaken the happines with in you<br/>
                    </p>
                </div>
            </div>
        </div>
        <div className="home__section our__team__section">
          {/* Cover */}
          <div class="mission-second home__content__cover__about home__content__cover__about__image ourteam__content__cover__about__image about__content__cover__about__image__header">
            <img 
              className='about__content__cover__about__image__header__img'
              src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1706204916/ynsphk3trrzwvwehzjl2.jpg" 
              alt=""
            />
          </div>
        </div>
      </section>

      {/* ================================================= */}
      {/* ==================== New Chapter Begin ===================== */}
      {/* ================================================= */}
      <section id='begining__cover__section'>
        <BeginingsCover />
      </section>

      {/* ================================================= */}
      {/* ==================== Towards ===================== */}
      {/* ================================================= */}
      <section id='toward__cover__section'>
        <TowardsCover />
      </section>

      {/* ================================================= */}
      {/* ==================== Inspiring ===================== */}
      {/* ================================================= */}
      <section id='inspiring__voices__section'>
        <InspiringVoices />
        <div class="mission-second home__content__cover__about home__content__cover__about__image">
          <img src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1706204919/dxeo0v7xkafoegtfrecr.jpg" alt="" />
        </div>
      </section>

      <section>
         {/* ================================================= */}
        {/* ================ Section Programs Card =================== */}
        {/* ================================================= */}
        <div>
          <SectionProgramCard />
        </div>
      </section>

    </div>
  )
}

export default About