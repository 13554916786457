import React from 'react'

const Depression = () => {
  return (
       /* ============================= We want to talk with you ================================= */
<div className="program">
    <div className="program__reconciliation">
        <div className="program__reconciliation__image">
            <img 
                src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1706645108/pexels-gerd-altmann-23180_m3chtx.jpg" 
                alt="Depression"
            />
        </div>
        <div className='program__reconciliation__text'>
            <p>
                Our depression program offers comprehensive support to help individuals overcome the challenges of depression and reclaim a fulfilling life.
            </p>
            <br/>
            <p>
                Our specialized professionals work in tandem with you to customize treatment, integrating innovative therapeutic approaches and compassionate guidance.
            </p>
            <br/>
            <p>
                Individual sessions provide a safe space to explore thoughts and emotions, while group therapies foster shared experiences and the building of support bonds.
            </p>
            <br/>
            <p>
                We believe in the importance of adopting a holistic approach. The program includes elements such as stress management, promoting a healthy lifestyle, and techniques to strengthen emotional resilience.
            </p>
        </div>  
    </div>    

    <div className="program__reconciliation__text__sub">
        <div className='program__reconciliation__text__sub'>
            <p>
                Together, we weave a network of hope and understanding, guiding each<br/>individual toward light and rebirth after dark times. You are not alone on this<br/>journey – let us accompany you towards a brighter life.
            </p>
        </div>  
    </div>            
</div>

/* =============================== We want to talk with you ================================= */
  )
}

export default Depression