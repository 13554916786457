import React from 'react'

const CoverClinic = () => {
  return (
     /* ============================= Cover Clinic ================================= */

<div className="testimonial about">
    <div className="main">
        <img 
            className='logo'
            src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1705872503/WhatsApp_Image_2024-01-15_at_09.14.46_klkslx.jpg" 
            alt="logo"
        /> 
    </div>

    {/* ===================================================================== */}
    <div className="main">
        <div className="main__team home__sub__testimony__about home__sub__testimony about__toward__container about__toward__container__sub">
            <div className="main__team__image home__sub__testimony__image about__toward__image__sub">
                <img 
                    src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1707257912/WhatsApp_Image_2024-02-02_at_23.26.38_adxhbi.jpg" 
                    alt="" 
                />
            </div>
        </div>
    </div>
</div>

/* =============================== Cover Clinic ================================= */
  )
}

export default CoverClinic