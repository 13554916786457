import React from 'react'
import ContactCover from '../component/general/section/pages/contact/sectionRightCover/ContactCover'
import CarteCover from '../component/general/section/pages/contact/sectionCenterCover/CarteCover'

const Contact = () => {
  return (
    <div id='contact__us'>
        <section>
        {/* ================================================= */}
        {/* ==================== Our Mission ===================== */}
        {/* ================================================= */}
        <div className="home__section our__team__section">
            <div class="mission home__section__mission">
                <div class="mission-frist home__section__mission__content about__page__mission__title">
                    <h1>Contact Us</h1>
                    <p className='page__title__text'>    
                        Your first step to well-being starts here. <br/>
                        Reach out to us for information or to take a book.<br/>
                        We are here to guide you.
                    </p>
                </div>
            </div>
        </div>
      </section>

      {/* ================================================= */}
      {/* ==================== Contact Cover ===================== */}
      {/* ================================================= */}
      <section>
        <ContactCover />
      </section>

      {/* ================================================= */}
      {/* ==================== Map ===================== */}
      {/* ================================================= */}
      <section>
        <CarteCover />
      </section>
    </div>
  )
}

export default Contact