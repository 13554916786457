import React from 'react'
import FreeConsultationForm from '../../../../form/FreeConsultationForm'

const BeginingsCover = () => {
  return (
            /* ============================= We want to talk with you ================================= */
<div className="program">
    <div className="program beginning__charpter">
        <div className="program__reconciliation beginning__charpter__begins">
            <div className="program__reconciliation__text beginning__charpter__begins__text">
                <h1 id='new__chapter__begins'>A New Charpter Begins</h1>
                <p>We understand the concerns you may be<br/> feeling ,but remember ,it's not the end of<br/> the word. It's the beginning of a new<br/>Phase towords healing.We are here for<br/>you,for your family,every step of the<br/> way.Together,we are Writing a new<br/>Charpter filled with hope and renewal.<br/></p>
            </div>
            <img className='program__reconciliation__image beginning__charpter__begins__image' src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1706204908/rdkyzwy0dellqjsf1jap.jpg" alt="" />
        </div>
        <div className="program__reconciliation beginning__charpter__begins beginning__charpter__begins__reverse">
            <img className='program__reconciliation__image beginning__charpter__begins__image' src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1706204918/fd4bhqyqvlpqgeuhktcf.jpg" alt="" />
            <div className="program__reconciliation__text beginning__charpter__begins__text">
                <h1>Listening to<br/>your Mental Well-being</h1>
                <p>in our mental health clinic,we are<br/>committed to caring for you well being<br/>with a bold and forword -thinking<br/>approach.<br/></p>
                <p>our seasoned experts,with a strong<br/>track record,provide you with serious<br/>and reassuring support. we antcipate<br/> your needs,guiding your journey with<br/> confidence and proativity.<br/></p>
                <p>Together, lets shape a balanced and<br/> flourishing mental future. Take the frist<br/> step toward a rewarding life becouse,<br/>with us, you mental health is  our top<br/>priority.<br/></p>
            </div>
        </div>
        <FreeConsultationForm />
    </div>    
    
</div>

/* =============================== We want to talk with you ================================= */
 
  )
}

export default BeginingsCover