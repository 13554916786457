import React from 'react'
import { HashLink as Link } from 'react-router-hash-link';

const Footer = () => {
    const phoneNumber = '+1 480-597-6454';
    const phoneNumbertwo = '+1 480-264-2922';
  return (
        /* ===================================== Icon Section ======================================= */

        <div className="mission">
            {/* ===================================== Desktop & tablet Icon Section ======================================= */}
        <div id='footer__desktop' className="mission-thrid footer__content">
            <div className="mission-thrid-terms">
                <div className="mission-thrid-terms-logo">
                    <div className="mission-thrid-terms-logo-symbole">
                        <img src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1705872503/WhatsApp_Image_2024-01-15_at_09.14.46_klkslx.jpg" alt="" />
                    </div>
                    <div className="mission-thrid-terms-logo-page">
                        <a href=""><img src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1706080390/instagram-genesis_n62mkf.png" alt="instagram" className="media"/></a>
                        <a href=""><img src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1706080390/facebook-genesis_ejsuuu.png" alt="facebook" className="media"/></a>
                        <a href=""><img src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1706080390/whatsapp-genesis_qrftkh.png" alt="whatsapp" className="media"/></a>
                    </div>
                </div>
                <div className="mission-thrid-terms-navigation">
                    <ul>
                        <a href="/home/#id" className="link">Home</a>
                        <a href="/programs/#" className="link">Programs</a>
                        <a href="/ourteam/#" className="link">Our Team</a>
                        <a href="/about/#" className="link">About</a>
                        <a href="/contact/#" className="link">Contact</a>
                    </ul>
                </div>
                <div className="mission-thrid-terms-contact">
                    <h2>Contact</h2>
                    <p className="adres">
                        GENESIS OUTPATIENT LLC<br/>
                        7303 E MAIN STREET. SUITE 106
                        MESA,<br/> 
                        ARIZONA 85208<br/>
                        <Link to={`tel:${phoneNumber}`} className="links">{phoneNumber}</Link>/
                        <Link to={`tel:${phoneNumbertwo}`} className="link"> {phoneNumbertwo}</Link>
                        <br/>
                        <Link to="mailto:contact@genesis.com" className="link">contact@genesisoutpatient.com</Link>
                    </p>
                    <div className="mission-thrid-terms-contact-name">
                        <input type="full name" name="full name"placeholder="Full name" />
                    </div>
                    <div className="mission-thrid-terms-contact-email">
                        <input type="email" name="email"placeholder="Email" />
                    </div>
                    <div className="mission-thrid-terms-contact-number">
                        <input type="phone number" name="phone number"placeholder="Phone number" />
                    </div>
                    <div className="mission-thrid-terms-contact-message">
                        <input type="message" name="message" placeholder="message" />
                    </div>
                    <div className="home__content__button home__content__button__blue__cover">
                    <button className="home__content__button__white button__footer">Send Message</button>
                    </div>
                </div>
            </div>
            <div className="mission-thrid-policy">
                <a href="/privacy/#" className="link">Privacy Policy</a>
                <a href="/cookies/#" className="link">Cookies Policy</a>
                <a>@ 2024 Genesis Out Patient LLC All right reserved</a>
            </div>
        </div>
        {/* ===================================== Mobile Icon Section ======================================= */}
        <div id='footer__mobile' className="mission-thrid footer__content">
            <div className="mission-thrid-terms">
                <div className="mission-thrid-terms-logo">
                    <div className="mission-thrid-terms-logo-symbole">
                            <img src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1705872503/WhatsApp_Image_2024-01-15_at_09.14.46_klkslx.jpg" alt="" />
                        </div>
                        <div className="mission-thrid-terms-logo-page">
                            <a href=""><img src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1706080390/instagram-genesis_n62mkf.png" alt="instagram" className="media"/></a>
                            <a href=""><img src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1706080390/facebook-genesis_ejsuuu.png" alt="facebook" className="media"/></a>
                            <a href=""><img src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1706080390/whatsapp-genesis_qrftkh.png" alt="whatsapp" className="media"/></a>
                        </div>
                    </div>
                    <div className="mission-thrid-terms-contact">
                        <h2>Contact</h2>
                        <p className="adres">
                            GENESIS OUTPATIENT LLC<br/>
                            7303 E MAIN STREET. SUITE 106
                            MESA,<br/> 
                            ARIZONA 85208<br/>
                            <Link to={`tel:${phoneNumber}`} className="links">{phoneNumber}</Link>/
                            <Link to={`tel:${phoneNumbertwo}`} className="link"> {phoneNumbertwo}</Link>
                            <br/>
                            <Link to="mailto:contact@genesis.com" className="link">contact@genesisoutpatient.com</Link>
                        </p>
                        <div className="mission-thrid-terms-contact-name">
                            <input type="full name" name="full name"placeholder="Full name" />
                        </div>
                        <div className="mission-thrid-terms-contact-email">
                            <input type="email" name="email"placeholder="Email" />
                        </div>
                        <div className="mission-thrid-terms-contact-number">
                            <input type="phone number" name="phone number"placeholder="Phone number" />
                        </div>
                        <div className="mission-thrid-terms-contact-message">
                            <input type="message" name="message" placeholder="message" />
                        </div>
                        <div className="home__content__button home__content__button__blue__cover">
                            <button className="home__content__button__white button__footer">Send Message</button>
                        </div>
                    </div>
                </div>
                <div className="mission-thrid-terms-navigation">
                    <ul>
                        <a href="/home/#id" className="link">Home</a>
                        <a href="/programs/#" className="link">Programs</a>
                        <a href="/ourteam/#" className="link">Our Team</a>
                        <a href="/about/#" className="link">About</a>
                        <a href="/contact/#" className="link">Contact</a>
                    </ul>
                </div>
                <div className="mission-thrid-policy">
                    <a href="/privacy/#" className="link">Privacy Policy</a>
                    <a href="/cookies/#" className="link">Cookies Policy</a>
                    <a>@ 2024 Genesis Out Patient LLC All right reserved</a>
                </div>
            </div>
        </div>

        /* ===================================== Icon Section ======================================= */
  )
}

export default Footer