import React from 'react'

const Negative = () => {
  return (
     /* ============================= Negative ================================= */
<div className="negative">
    <div className="program__reconciliation__text__sub">
        <div className='program__reconciliation__text__sub'>
            <p>
                Our negativity program is specially designed to assist those seeking to shift<br/> their perspective and cultivate a positive mindset.  
            </p>
            <br/>
            <p>
                Guided by experienced professionals, we offer innovative approaches and<br/> well-established practices to overcome negative thought patterns and foster<br/> an optimistic state of mind.
            </p>
        </div>  
    </div>        

    <div className="program__reconciliation">
        <div className="program__reconciliation__image program__reconciliation__image__anxiete program__reconciliation__image__anxiete__cover__left">
            <img 
                src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1706645108/pexels-alex-green-5699756_z4ztwn.jpg" 
                alt="Negative"
            />
        </div>
        <div className='program__reconciliation__text__anxiete__text__right program__reconciliation__text__list__left'>
            <h4>Key Components:</h4>
            <div>
                <h4>1.Personalized Assessment:</h4>
                <p>
                    We begin by understanding the specific sources of negativity, allowing for an individualized approach.
                </p>
            </div>
            
            <h4>2.Positive Thinking Coaching: </h4>
            <p>
                Coaching sessions aim to identify and transform negative thoughts into positive and constructive ones.
            </p>
            <h4>3.Cognitive-Behavioral Therapy (CBT):</h4>
            <p>
                CBT is utilized to target negative thought patterns and promote positive changes in behavior.
            </p>
            <h4>4.Mindfulness Practices:</h4>
            <p>
                Mindfulness exercises help develop self-awareness and manage negative thoughts in a constructive way.
            </p>
            <h4>5.Encouragement of Emotional Well-being:</h4>
            <p>
                We explore techniques to strengthen emotional resilience and foster a positive mental balance.
            </p>
            <h4>6.Group Workshops:</h4>
            <p>
                Workshops encourage the sharing of experiences and the creation of mutual support to overcome negativity.
            </p>
        </div>  
    </div>    

    <div className="program__reconciliation__text__sub">
        <div className='program__reconciliation__text__sub'>
            <h5>Join us to illuminate your path towards positivity.</h5>
            <p>Transform negativity into a driving force for an enriching life.</p>
        </div>  
        <div className="program__reconciliation__image program__reconciliation__image__anxiete program__reconciliation__image__anxiete__text__sub">
            <img 
                src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1706645108/pexels-jill-wellington-40815_1_tin7bo.jpg" 
                alt="Negative Joy"
            />
        </div>
    </div>            
</div>

/* =============================== Negative ================================= */
  )
}

export default Negative