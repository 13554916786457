import React, { useEffect, useRef } from "react";
import { HashLink as Link } from 'react-router-hash-link';
import userImg from "../../../assets/images/avatar.jpg"
import { BiMenu } from "react-icons/bi";


const Navbar = () => {

    const headerRef = useRef(null)
    const menuRef = useRef(null)

    const handleStickyHeader = () => {
        window.addEventListener('scroll', () => {
            if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 8) {
                headerRef.current.classList.add('sticky__header')
            } else {
                headerRef.current.classList.remove('sticky__header')
            }
        })
    }

    useEffect(() => {
        handleStickyHeader()

        return () => window.removeEventListener('scroll', handleStickyHeader)
    })

    const toggleMenu = () => menuRef.current.classList.toggle('show__menu')
    return (
    /* ======================================= Navbar ========================================= */
        <header className="fixed" ref={headerRef}>
                <nav>
                    <div className="logo">
                        <img src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1705872503/WhatsApp_Image_2024-01-15_at_09.14.46_klkslx.jpg" alt="logo" />
                    </div>
                    <div className="menu navigation" ref={menuRef} onClick={toggleMenu}>
                        <ul>
                            <a href="/#" passHref>Home</a>
                            <a href="/programs/#" passHref>Programs</a>
                            <a href="/ourteam/#" passHref>Our Team</a>
                            <a href="/about/#" passHref>About</a>
                            <a href="/login/#" passHref>Login</a>
                            <a href="/register/#" passHref>Register</a>
                            <a href="/contact/#" passHref>Contact</a>

                            {/* ========== Login ============ */}
                            <div className="hidden">
                                <a href="/#" passHref>
                                    <figure
                                        className="nav__user__container"
                                    >
                                        <img 
                                            src={userImg} 
                                            className="nav__user__img"
                                            alt="" 
                                        />
                                    </figure>
                                </a>
                            </div>
                        </ul>
                    </div>        

                    <span className="mobile__menu">
                    <span className="md:hidden hamburger__text" onClick={toggleMenu}>
                        <p>MENU</p>
                    </span>
                    <span className="md:hidden hamburger" onClick={toggleMenu}>
                        <BiMenu />
                    </span> 
                    </span>
                </nav>
                       
        </header>
    /* ======================================= Navbar ========================================= */
    )
}

export default Navbar