import React from 'react'
import { Form } from "antd";
import { HashLink as Link } from 'react-router-hash-link';

const ProgramCover = () => {
  return (
    /* ============================= We want to talk with you ================================= */
<div className="program">
    <div className="program__reconciliation">
        <div className="program__width__laptop__text program__reconciliation__text">
            <h1>Towards Healing<br/>and Reconciliation</h1>
            <h3>
                Let us be your guide to individual healing  <br/>and restoring harmony with your loved <br/>ones. Your future begins with our <br/>compassionate support.
            </h3>
            <Link to='/programs/#'>
                <button className="button__programs">Programs</button>
            </Link> 
        </div>
        <img className="program__width__laptop__image program__reconciliation__image" src="https://res.cloudinary.com/dhxqidnfl/image/upload/v1705952557/pexels-antoni-shkraba-7579306_bmyxjm.jpg" alt="Healing" />    
    </div>            
</div>

/* =============================== We want to talk with you ================================= */
  )
}

export default ProgramCover